import Glide from '@glidejs/glide'

const itemCardCarousels = document.querySelectorAll(".item-carousel__inner")

const itemCardCarouselsArray = Array.from(itemCardCarousels) || []

if(itemCardCarousels){

  itemCardCarouselsArray.forEach(item => {
    var glide = new Glide(item, {
      type: 'slider',
      perView: 3,
      gap: 20,
      rewind: false,
      breakpoints: {
        1100: {
          perView: 2.5
        },
        800: {
          perView: 1.7
        },
        550: {
          perView: 1.2
        },
      }
    })

    const slides = item.querySelectorAll(".item-carousel__item");
    const slideCount = getSlideCount(slides);
    
    if (slideCount < glide.settings.perView) {
      const leftButton = item.querySelector('.item-carousel__arrow-left');
      const rightButton = item.querySelector('.item-carousel__arrow-right');
      leftButton.classList.add("hidden");
      rightButton.classList.add("hidden");
    }
    
    glide.mount()
  
    const carouselRange = item.querySelector(".item-carousel__range") as HTMLInputElement
  
    carouselRange.addEventListener("input", e => {
        setTimeout(() => {
          glide.go(`=${carouselRange.value}`)
        }, 500);
    })
  
    glide.on('move.after', () =>{
      const currentIndex = glide.index;
      carouselRange.value = currentIndex
      const rightButton = item.querySelector('.item-carousel__arrow-right');
      if(currentIndex > slideCount -4) {
        rightButton.classList.add('glide__arrow--disabled');
      } else if (currentIndex < slideCount -3) {
        rightButton.classList.remove('glide__arrow--disabled');
      }
    })
  })
}

function getSlideCount(slide){
  const lengthOfSlides = Array.from(slide).length;
  return lengthOfSlides;
}

if(itemCardCarousels){
  itemCardCarouselsArray.forEach(item => {
    const slides = item.querySelectorAll(".item-carousel__item")
    const carouselRange = item.querySelector(".item-carousel__range") as HTMLInputElement
  
    setRangeMax(slides, carouselRange)
  })
}

function setRangeMax(arr, input) {
  const slidesLength = Array.from(arr).length
  const rangeMax = (slidesLength - 3).toString()
  input.max = rangeMax

  setRangeThumbWidth(rangeMax, input)
}

function setRangeThumbWidth(max, input) {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");

  if(max === "1"){
    input.style.setProperty('--thumb-size', "100px");
  }
}