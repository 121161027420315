import Glide from '@glidejs/glide'

const fullMediaCarousels = document.querySelectorAll(".full-media-carousel__inner")

const fullMediaCarouselsArray = Array.from(fullMediaCarousels) || []

if(fullMediaCarousels){

  fullMediaCarouselsArray.forEach(item => {

    const mediaItems = item.querySelectorAll('.full-media-carousel__item');
    const leftButton = item.querySelector('.full-media-carousel__arrow-left');
    const rightButton = item.querySelector('.full-media-carousel__arrow-right');

    if (leftButton.classList.contains('glide__arrow--disabled') && rightButton.classList.contains('glide__arrow--disabled')) {
        leftButton.classList.add("hidden");
        rightButton.classList.add("hidden");
    }

    var glide = new Glide(item, {
      type: 'slider',
      perView: 1,
      gap: 0,
      rewind: true,
    })

    if (mediaItems.length == 1) {
      const leftButton = item.querySelector('.full-media-carousel__arrow-left');
      const rightButton = item.querySelector('.full-media-carousel__arrow-right');
      leftButton.classList.add('hidden');
      rightButton.classList.add('hidden');
    }
    
    glide.mount()
  })
}