import Glide from '@glidejs/glide'

const largeIconGrid = document.querySelectorAll(".large-icons-grid__carousel-inner");
if(largeIconGrid){

  largeIconGrid.forEach(item => {
    var glide = new Glide(item, {
      type: 'slider',
      perView: 3.5,
      gap: 20,
      rewind: false,
      breakpoints: {
        1200: {
          perView: 2.5,
        },
        760: {
          perView: 1.7,
        },
        550: {
          perView: 1.2,
        },
      }
    })

  const largeIconsCarouselRange = document.querySelector(".large-icons-grid__carousel-range") as HTMLInputElement
  const items = item.querySelectorAll(".large-icons-grid__carousel-card")
  const itemCount = Array.from(items).length;

  if (itemCount == 1) {
    const rightArrow = item.querySelector('.large-icons-grid__carousel-arrow-right');
    const leftArrow = item.querySelector('.large-icons-grid__carousel-arrow-left');
    rightArrow.classList.add('hidden');
    leftArrow.classList.add('hidden');
  }

  glide.mount()

  largeIconsCarouselRange.addEventListener("input", e => {
      setTimeout(() => {
        glide.go(`=${largeIconsCarouselRange.value}`)
      }, 500);
  })

  glide.on('move.after', () =>{
    const largeIconsCurrentIndex = glide.index;
    largeIconsCarouselRange.value = largeIconsCurrentIndex
    const rightButton = item.querySelector(".large-icons-grid__carousel-arrow-right");
    if(largeIconsCurrentIndex > itemCount -1) {
      rightButton.classList.add('glide__arrow--disabled');
    } else if (largeIconsCurrentIndex < itemCount -1) {
      rightButton.classList.remove('glide__arrow--disabled');
    }
  })
})
}

if(largeIconGrid){
  largeIconGrid.forEach(item => {
  const slides = item.querySelectorAll(".large-icons-grid__carousel-card")
  const largeIconsCarouselRange = item.querySelector(".large-icons-grid__carousel-range") as HTMLInputElement

  setRangeMax(slides, largeIconsCarouselRange)
})
}

function setRangeMax(arr, input) {
  const slidesLength = Array.from(arr).length
  const rangeMax = (slidesLength - 1).toString()
  input.max = rangeMax

  setRangeThumbWidth(rangeMax, input)
}

function setRangeThumbWidth(max, input) {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");
  
  if(max === "1"){
    input.style.setProperty('--thumb-size', "100px");
  }
}