import Glide from '@glidejs/glide'

const careerCarousels = document.querySelectorAll(".careers-carousel__inner")

const careerCarouselsArray = Array.from(careerCarousels) || []

if(careerCarousels){

  careerCarouselsArray.forEach(item => {

    var glide = new Glide(item, {
      type: 'slider',
      perView: 3.5,
      gap: 20,
      rewind: false,
      breakpoints: {
        1200: {
          perView: 2.5,
        },
        760: {
          perView: 1.7,
        },
        550: {
          perView: 1.2,
        },
      }
    })
    
    const careers = item.querySelectorAll(".careers-carousel__item");
    const careerCount = Array.from(careers).length;
    if (careerCount <= glide.settings.perView) {
      const leftButton = item.querySelector('.careers-carousel__arrow-left');
      const rightButton = item.querySelector('.careers-carousel__arrow-right');
      const slider = item.querySelector('.careers-carousel__range') as HTMLElement;
      leftButton.classList.add('hidden');
      rightButton.classList.add('hidden');
      slider.style.display = 'none';
    }

    glide.mount()
  
    const carouselRange = item.querySelector(".careers-carousel__range") as HTMLInputElement
  
    carouselRange.addEventListener("input", e => {
        setTimeout(() => {
          glide.go(`=${carouselRange.value}`)
        }, 500);
    })

    glide.on('move.after', () =>{
      const currentIndex = glide.index;
      carouselRange.value = currentIndex
      const rightButton = item.querySelector('.careers-carousel__arrow-right');
      if(currentIndex > careerCount) {
        rightButton.classList.add('glide__arrow--disabled');
      } else if (currentIndex < careerCount -3) {
        rightButton.classList.remove('glide__arrow--disabled');
      }
    })
  })
}

if(careerCarousels){
  careerCarouselsArray.forEach(item => {
    const slides = item.querySelectorAll(".careers-carousel__item")
    const carouselRange = item.querySelector(".careers-carousel__range") as HTMLInputElement

    const leftButton = item.querySelector('.careers-carousel__arrow-left');
    const rightButton = item.querySelector('.careers-carousel__arrow-right');

    if (leftButton.classList.contains('glide__arrow--disabled') && rightButton.classList.contains('glide__arrow--disabled')) {
        leftButton.classList.add("hidden");
        rightButton.classList.add("hidden");
    }
  
    setCareersCarouselRangeMax(slides, carouselRange)
  })
}

function setCareersCarouselRangeMax(arr, input) {
  const slidesLength = Array.from(arr).length
  const rangeMax = (slidesLength - 3).toString()
  input.max = rangeMax

  setCareersCarouselRangeThumbWidth(rangeMax, input)
}

function setCareersCarouselRangeThumbWidth(max, input) {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");
  
  if(max === "1"){
    input.style.setProperty('--thumb-size', "100px");
  }
}